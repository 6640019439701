<template>
  <div>
    <div class="bodySec" id="Geolocalidades-principal">
      <div class="rowTitle">
        <span class="boxInfo text-center">
          <span class="labelBox sb-3" :id="'B3Detalhes-0'">
            CEP Principal
          </span>
          <span class="cepBox" style="font-family: system-ui">
            -------------------------------------------------------
            <p class="infoSB sb-3 text-center">
              {{ formatarCEP(resultados.bk_300_003_cep_1) }}
            </p>
            -------------------------------------------------------
          </span>
        </span>
      </div>
      <div class="rowSec">
        <span class="boxInfo sb-right sb-5">
          <p class="labelSB" :id="'B3Detalhes-1'">Logradouro</p>
          <p class="infoSB sb-5">
            <span v-if="resultados.bk_300_003_cep_1_tipo_logradouro != '-'">
              {{ resultados.bk_300_003_cep_1_tipo_logradouro }}
            </span>
            {{ resultados.bk_300_003_cep_1_logradouro }}
          </p>
        </span>
        <span class="boxInfo sb-right sb-3">
          <p class="labelSB" :id="'B3Detalhes-2'">Município</p>
          <p class="infoSB sb-3">
            {{ resultados.bk_300_003_cep_1_municipio }}
          </p>
        </span>
        <span class="boxInfo sb-1">
          <p class="labelSB" :id="'B3Detalhes-3'">UF</p>
          <p class="infoSB sb-1">{{ resultados.bk_300_003_cep_1_uf }}</p>
        </span>
      </div>
      <div class="rowSec">
        <span class="boxInfo sb-right sb-3">
          <p class="labelSB" :id="'B3Detalhes-4'">Bairro</p>
          <p class="infoSB sb-3">{{ resultados.bk_301_001_bairro }}</p>
        </span>
        <span class="boxInfo sb-right sb-3">
          <p class="labelSB" :id="'B3Detalhes-5'">
            Descrição adicional da localidade
          </p>
          <p class="infoSB sb-3">
            {{ resultados.bk_301_001_logradouro_complemento }}
          </p>
        </span>
        <span class="boxInfo sb-right sb-2">
          <p class="labelSB" :id="'B3Detalhes-6'">População estimada</p>
          <p class="infoSB sb-2">
            {{ formatarNumeroComPonto(resultados.bk_301_003_pop_total) }}
          </p>
        </span>
        <span class="boxInfo sb-1">
          <p class="labelSB" :id="'B3Detalhes-7'">Área</p>
          <p class="infoSB sb-1">
            {{ formatarNumeroComPonto(this.resultados.bk_301_001_area_km_2) }}
            km²
          </p>
        </span>
      </div>
      <div class="rowSec">
        <span class="boxInfo sb-right sb-3">
          <p class="labelSB" :id="'B3Detalhes-8'">Precisão do CEP</p>
          <span class="infoSB sb-3" id="infoLog">
            <span
              v-for="(cepClass, j) in ['Único', 'Parcial', 'Múltiplo']"
              :key="j"
              :class="{
                selecionado:
                  resultados.bk_300_003_cep_1_precisao ===
                  normalizeString(cepClass),
                'nao-selecionado':
                  resultados.bk_300_003_cep_1_precisao !==
                  normalizeString(cepClass),
              }"
              :id="'B3Detalhes8-' + cepClass"
            >
              {{ cepClass }}
              <span v-if="j < 2" style="color: black">|</span>
            </span>
          </span>
        </span>
        <span class="boxInfo sb-6">
          <p class="labelSB" :id="'B3Detalhes-9'">Classe do CEP</p>
          <p class="infoSB sb-6" id="infoLog">
            <span
              v-for="(cepClass, j) in [
                'Padrão',
                'Especial',
                'Promocional',
                'Comunitário',
                'Correios',
              ]"
              :key="j"
              :class="{
                selecionado:
                  resultados.bk_301_001_classe_cep ===
                  normalizeString(cepClass),
                'nao-selecionado sb-right':
                  resultados.bk_301_001_classe_cep !==
                  normalizeString(cepClass),
              }"
              :id="'B3Detalhes9-' + cepClass"
            >
              {{ cepClass }}
              <span v-if="j < 4" style="color: black">|</span>
            </span>
          </p>
        </span>
      </div>
      <div class="rowSec">
        <span class="boxInfo sb-3 sb-right">
          <p class="labelSB" :id="'B3Detalhes-10'">Tipo de Input (CEP)</p>
          <p class="infoSB sb-3">
            <span
              v-for="(tipo, j) in ['Informado', 'Inferido']"
              :key="j"
              :class="{
                selecionado: tipoInputCep === tipo,
                'nao-selecionado sb-right': tipoInputCep !== tipo,
              }"
            >
              {{ tipo }}
              <span v-if="j < 1" style="color: black">|</span>
            </span>
          </p>
        </span>
        <span class="boxInfo sb-3 sb-right">
          <p class="labelSB" :id="'B3Detalhes-11'">
            Match com Histórico de Endereços
          </p>
          <p class="infoSB sb-3">
            <span
              v-for="(ender, e) in ['Novo', 'Existente', 'Não localizado']"
              :key="e"
              :class="{
                selecionado: resultados.bk_300_003_match_historico === ender,
                'nao-selecionado sb-right':
                  resultados.bk_300_003_match_historico !== ender,
              }"
              :id="'B3Detalhes11-' + ender + '-' + tipoInputCep"
            >
              {{ ender }}
              <span v-if="e < 2" style="color: black">|</span>
            </span>
          </p>
        </span>
        <span class="boxInfo sb-right sb-1">
          <p class="labelSB" :id="'B3Detalhes-12'">CPF: Match c/ UF</p>
          <p class="infoSB sb-1">
            <Check :valor="resultados.bk_101_005_indicador_match_cpf_uf_cep" />
          </p>
        </span>
        <span class="boxInfo sb-right sb-1">
          <p class="labelSB" :id="'B3Detalhes-13'">CPF: Match c/ Região</p>
          <p class="infoSB sb-1">
            <Check
              :valor="resultados.bk_101_005_indicador_match_cpf_regiao_cep"
            />
          </p>
        </span>
        <span class="boxInfo sb-1">
          <p class="labelSB" :id="'B3Detalhes-14'">Distância à UF do CPF</p>
          <p class="infoSB sb-1">
            {{
              formatarNumeroComPonto(
                resultados.bk_101_005_num_distancia_cpf_uf_cep
              )
            }}
            <span
              v-if="
                resultados.bk_101_005_num_distancia_cpf_uf_cep != '-' &&
                resultados.bk_101_005_num_distancia_cpf_uf_cep != 0
              "
            >
              km
            </span>
          </p>
        </span>
      </div>
      <div class="rowSec">
        <span class="boxInfo sb-1 sb-right">
          <p class="labelSB" :id="'B3Detalhes-15'">Check Capital</p>
          <p class="infoSB sb-1">
            <Check :valor="resultados.bk_301_005_indicador_capital" />
          </p>
        </span>
        <span class="boxInfo sb-3 sb-right">
          <p class="labelSB" :id="'B3Detalhes-16'">Capital mais próxima</p>
          <p class="infoSB sb-3">
            {{ resultados.bk_301_005_capital_mais_proxima }}
          </p>
        </span>
        <span class="boxInfo sb-1 sb-right">
          <p class="labelSB" :id="'B3Detalhes-17'">Distância Capital</p>
          <p class="infoSB sb-1">
            {{
              formatarNumeroComPonto(
                resultados.bk_301_005_num_distancia_capital_mais_proxima
              )
            }}
            <span
              v-if="
                resultados.bk_301_005_num_distancia_capital_mais_proxima !=
                  '-' &&
                resultados.bk_301_005_num_distancia_capital_mais_proxima != 0
              "
            >
              km
            </span>
          </p>
        </span>
        <span class="boxInfo sb-1">
          <p class="labelSB" :id="'B3Detalhes-17-2'">Distância Fronteira</p>
          <p class="infoSB sb-1">
            {{ formatarNumeroComPonto(resultados.bk_307_001_distancia) }}
            <span
              v-if="
                resultados.bk_307_001_distancia != '-' &&
                resultados.bk_307_001_distancia != 0
              "
            >
              km
            </span>
          </p>
        </span>
      </div>
      <div class="rowSec">
        <span class="boxInfo sb-1 sb-right">
          <p class="labelSB" :id="'B3Detalhes-18'">Check Dormitório</p>
          <p class="infoSB sb-1">
            <Check :valor="resultados.bk_301_005_indicador_check_dormitorio" />
          </p>
        </span>
        <span class="boxInfo sb-3 sb-right">
          <p class="labelSB" :id="'B3Detalhes-19'">Cidade mais próxima</p>
          <p
            class="infoSB sb-3"
            v-if="resultados.bk_301_005_municipio_mais_proximo != null"
          >
            {{ resultados.bk_301_005_municipio_mais_proximo }}
          </p>
          <p
            class="infoSB"
            v-if="resultados.bk_301_005_municipio_mais_proximo == null"
          >
            -
          </p>
        </span>
        <span class="boxInfo sb-1 sb-right">
          <p class="labelSB" :id="'B3Detalhes-20'">Distância Cidade</p>
          <p
            class="infoSB sb-1"
            v-if="
              resultados.bk_301_005_num_distancia_municipio_mais_proximo != null
            "
          >
            {{
              formatarNumeroComPonto(
                resultados.bk_301_005_num_distancia_municipio_mais_proximo
              )
            }}
            <span
              v-if="
                resultados.bk_301_005_num_distancia_municipio_mais_proximo !=
                  0 &&
                resultados.bk_301_005_num_distancia_municipio_mais_proximo !=
                  '-'
              "
            >
              km
            </span>
          </p>
          <p class="infoSB sb-1" v-else>-</p>
        </span>
        <span class="boxInfo sb-2 sb-right">
          <p class="labelSB" :id="'B3Detalhes-21'">
            Pop. estimada da Cidade Próxima
          </p>
          <p
            class="infoSB sb-2"
            v-if="
              resultados.bk_301_005_num_populacao_estimada_municipio_mais_proximo !=
              null
            "
          >
            {{
              formatarNumeroComPonto(
                resultados.bk_301_005_num_populacao_estimada_municipio_mais_proximo
              )
            }}
          </p>
          <p class="infoSB sb-2" v-else>-</p>
        </span>
        <span class="boxInfo sb-2">
          <p class="labelSB" :id="'B3Detalhes-22'">Área da Cidade Próxima</p>
          <p
            class="infoSB sb-2"
            v-if="
              resultados.bk_301_005_num_area_km2_municipio_mais_proximo != null
            "
          >
            {{
              formatarNumeroComPonto(
                resultados.bk_301_005_num_area_km2_municipio_mais_proximo
              )
            }}
            <span
              v-if="
                resultados.bk_301_005_num_area_km2_municipio_mais_proximo !=
                  '-' &&
                resultados.bk_301_005_num_area_km2_municipio_mais_proximo != 0
              "
            >
              km²
            </span>
          </p>
          <p class="infoSB sb-2" v-else>---</p>
        </span>
      </div>
      <div class="rowSec smaller" id="Geolocalidades-enderecos">
        <div class="tabelaResultados sb-9">
          <div class="linhaCabecalho">
            <div class="cabecalhoEds sb-3" style="margin-right: 10px"></div>
            <div
              class="cabecalhoEds sb-2 fundoAzul text-center"
              v-for="(indiceSuperior, indexSuperior) in indicesSuperiores"
              :key="indexSuperior"
              :id="'B3Detalhes24-' + indexSuperior"
            >
              {{ indiceSuperior }}
            </div>
          </div>
          <tbody class="tabela-corpo">
            <tr>
              <div
                class="colunaUm fundoAzul sb-3 text-left"
                :id="'B3Detalhes-23'"
              >
                Match Polígono
              </div>
              <td
                class="textos-resultados text-center"
                v-for="(valor1, indexValor1) in tabelaCruzada1linha"
                :key="indexValor1"
              >
                <span class="cell sb-2" v-if="indexValor1 == 0">
                  <Check :valor="valor1" />
                </span>
                <span class="cell sb-2" v-if="indexValor1 != 0">
                  <span class="nao-selecionado">
                    {{ valor1 }}
                  </span>
                </span>
              </td>
            </tr>
            <tr
              v-for="(indiceLateral, indexLateral) in indicesLaterais"
              :key="indexLateral"
            >
              <div
                class="colunaUm fundoAzul sb-3 text-center"
                :class="{ nota: indexLateral == 4 }"
                :id="'B3Detalhes25-' + indexLateral"
              >
                {{ indiceLateral }}
              </div>
              <td
                class="textos-resultados text-center"
                v-for="(valor, indexValor) in tabelaCruzada[indexLateral]"
                :key="indexValor"
                :id="'B3Detalhes26-' + indexLateral + '-' + indexValor"
              >
                <span
                  class="cell sb-2"
                  v-if="valor != 'Não se aplica'"
                  :class="{ nota: indexLateral == 4 }"
                >
                  {{ valor }}
                </span>
                <span
                  class="cell nao-selecionado"
                  v-if="valor == 'Não se aplica'"
                >
                  {{ valor }}
                </span>
              </td>
            </tr>
          </tbody>
        </div>
      </div>
      <div class="rowSec bigger">
        <B3Mapa
          v-if="codigo_agrupador != '' && this.abreBloco"
          :index="index"
          :abreBloco="abreBloco"
          :codigo_agrupador="codigo_agrupador"
          :resultados="resultados"
          :exibirTootipsConsultas="exibirTootipsConsultas"
        />
      </div>
      <div
        class="sub"
        v-if="resultados.bk_300_003_cep_1_precisao == 'Multiplo'"
      >
        Observação: Para regiões de CEP's classificados com precisão "Múltiplo",
        os polígonos municipais e de áreas urbanas poderão apresentar formatos
        atípicos, em detrimentos de aproximações trigonométricas
      </div>
      <div class="rowSec bigger">
        <B3Indices
          :resultados="resultados"
          :index="index"
          :abreBloco="abreBloco"
          :exibirTootipsConsultas="exibirTootipsConsultas"
        />
      </div>
      <div class="rowSec bigger">
        <B3PerfilCep
          :resultados="resultados"
          :index="index"
          :abreBloco="abreBloco"
          :exibirTootipsConsultas="exibirTootipsConsultas"
        />
      </div>
      <div class="rowSec bigger">
        <span
          class="titleBlue sb-right"
          :id="'B3Detalhes-rank'"
          style="width: 70px"
        >
          Ranking
        </span>
        <span
          class="titleBlue sb-9"
          :id="'B3Detalhes-rank2'"
          style="width: 875px"
        >
          Top 05 maiores empresas da região, conforme capital social
        </span>
      </div>
      <B3Ranking
        v-for="(item, index) in Array(5)"
        :key="index"
        :item="item"
        :index="index"
        :abreBloco="abreBloco"
        :resultados="resultados"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :gerandoPdf="gerandoPdf"
      />
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsBloco3Filtrados" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import B3Mapa from "@/components/SuperBox/B3Mapa.vue";
import B3Indices from "@/components/SuperBox/B3Indices.vue";
import B3PerfilCep from "@/components/SuperBox/B3PerfilCep.vue";
import B3Ranking from "@/components/SuperBox/B3Ranking.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B3Detalhes",

  components: {
    Check,
    B3Mapa,
    B3Indices,
    B3PerfilCep,
    B3Ranking,
  },

  props: {
    index: Number,
    abreBloco: Boolean,
    codigo_agrupador: String,
    resultados: Object,
    parametros: Object,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      indicesSuperiores: [
        "Endereço Desfavorecido Socialmente (EDS)",
        "Endereço Favorecido Socialmente (EFS)",
        "Endereço de Altíssimo Padrão (EAP)",
      ],
      indicesLaterais: [
        "Distância Mínima (km)",
        "Quantidade: Raio 01 km",
        "Quantidade: Raio 03 km",
        "Quantidade: Raio 05 km",
        "Notificação Adicional",
        "Tamanho do polígono mais próximo (km²)",
        "Nome / Descritivo do polígono (comunidade)",
      ],
      showDivFlag: new Array(3).fill(false),
    };
  },

  methods: {
    formatarCEP(numero) {
      if (numero > 0 && numero != "-") {
        let cepLimpo = numero.replace(/\D/g, "");

        if (cepLimpo.length !== 8) {
          return numero;
        }

        return cepLimpo.replace(/^(\d{5})(\d{3})$/, "$1-$2");
      } else {
        return numero;
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        if (numero == 0) {
          var numeroString = numero.toString().replace(".", ",");
        } else {
          var numeroString = Number(numero)
            .toFixed(1)
            .toString()
            .replace(".", ",");
        }
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    normalizeString(str) {
      if (str != "-" && str != null) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } else {
        return str;
      }
    },

    formatarNumero(numero) {
      if ((numero > 0) & (numero != "-")) {
        let numeroString = numero.toString();

        let partes = [];
        while (numeroString.length > 3) {
          partes.unshift(numeroString.slice(-3));
          numeroString = numeroString.slice(0, -3);
        }
        partes.unshift(numeroString);

        return partes.join(".");
      } else {
        return numero;
      }
    },
  },

  computed: {
    tabelaCruzada1linha() {
      return [
        this.resultados.bk_303_001_indicador_eds,
        "Não se aplica",
        "Não se aplica",
      ];
    },
    tabelaCruzada() {
      return {
        0: [
          this.resultados.bk_303_001_distancia_minima,
          this.resultados.bk_303_003_distancia_minima,
          this.resultados.bk_303_004_distancia_minima,
        ],
        1: [
          this.resultados.bk_303_001_qtde_eds_1_km,
          this.resultados.bk_303_003_qtde_efs_1_km,
          this.resultados.bk_303_004_qtde_eap_1_km,
        ],
        2: [
          this.resultados.bk_303_001_qtde_eds_3_km,
          this.resultados.bk_303_003_qtde_efs_3_km,
          this.resultados.bk_303_004_qtde_eap_3_km,
        ],
        3: [
          this.resultados.bk_303_001_qtde_eds_5_km,
          this.resultados.bk_303_003_qtde_efs_5_km,
          this.resultados.bk_303_004_qtde_eap_5_km,
        ],
        4: [
          this.resultados.bk_303_001_des_notificacao_adicional,
          this.resultados.bk_303_003_des_notificacao_adicional,
          this.resultados.bk_303_004_des_notificacao_adicional,
        ],
        5: [
          this.resultados.bk_303_013_num_area_eds_mais_proximo_ate5km_km2,
          "Não se aplica",
          "Não se aplica",
        ],
        6: [
          this.resultados.bk_303_001_comunidade,
          "Não se aplica",
          "Não se aplica",
        ],
      };
    },

    tipoInputCep() {
      if (this.parametros.cep == "") {
        return "Inferido";
      } else {
        return "Informado";
      }
    },

    tooltipsBloco3() {
      return listaTooltips.bloco03_detalhes;
    },

    tooltipsBloco3Filtrados() {
      const detalhesIniciais = this.tooltipsBloco3.filter((detalhe) => {
        return detalhe.target.startsWith("B3Detalhes11");
      });

      const detalhesFiltrados = detalhesIniciais.filter((detalhe) => {
        return detalhe.target.endsWith(this.tipoInputCep);
      });

      const tooltipsSemDetalhesIniciais = this.tooltipsBloco3.filter((tt) => {
        return !detalhesIniciais.includes(tt);
      });

      return [...tooltipsSemDetalhesIniciais, ...detalhesFiltrados];
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.bodySec {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 950px;
}

.rowTitle {
  width: 100%;
}
.rowSec {
  display: flex;
  flex: 1;
  width: 715px;
  align-items: flex-end;
  margin: 0 0 0 5px;
  min-height: 80px;

  &.bigger {
    width: 950px;
  }

  &.smaller {
    width: 640px;
    margin: 0px 0 0 5px;
  }
}

.sub {
  font-size: 12px;
  color: #0070c0;
  line-height: 12px;
  text-align: left;
  margin: 5px 0 5px 15px;
  width: 99%;
  position: relative;
  bottom: 27px;
}

.boxInfo {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.labelSB {
  margin: 0 0 0 0px !important;
  padding: 0 5px 0 5px;
  font-size: 12px;
  color: #0070c0;
  width: fit-content;
  cursor: default;
  line-height: 16px;
}
.labelBox {
  font-size: 12px;
  color: #0070c0;
  margin: 0 !important;
  cursor: default;
  width: fit-content;
  align-self: center;
}

.cepBox {
  cursor: default;
  display: flex;
  color: #0070c0;
  align-items: center;
  justify-content: center;
}

.infoSB {
  height: 40px;
  margin: 0px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: black;
  cursor: default;
  gap: 3px;
  line-height: 16px;
  text-align: center;
}

.selecionado {
  color: #68a490;
  margin-inline: 3px;
  font-weight: 600;
}

.nao-selecionado {
  color: #a6a6a6;
  // margin-inline: 3px;
}
.tabelaResultados {
  margin-top: 20px;
  // width: 700px !important;
}

.linhaCabecalho {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cabecalhoEds {
  height: 45px;
  vertical-align: middle !important;
  padding: 2px 5px !important;
  font-size: 12px;
  font-weight: 400;
  border: none;
  cursor: default;
  line-height: 14px;
  margin: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colunaUm {
  height: 40px;
  padding: 2px 30px !important;
  font-size: 12px;
  font-weight: 400;
  border: none;
  cursor: default;
  line-height: 14px;
  margin: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.nota {
    height: 70px;
  }
}

.fundoAzul {
  background-color: #d9e1f2;
  color: #363636;
  border-radius: 5px;
}

.tabela-corpo {
  border: none !important;
}

.textos-resultados {
  height: 40px;
  padding: 0 5px 5px 0px !important;
  cursor: default;
  line-height: 16px;
}
.cell {
  border: solid 1px #0070c0;
  border-radius: 10px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 14px;
  min-height: 35px;

  &.nota {
    font-size: 11px;
    line-height: 13px;
  }
}

.titleBlue {
  background-color: #0070c0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  margin-top: 20px;
  cursor: default;
  line-height: 18px;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
