<template>
  <div class="bodySec" id="Geolocalidades-indices">
    <div class="rowSec">
      <span class="titleBlue tit"> Índices de Caracterização do Entorno </span>
    </div>
    <div class="rowSec">
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #374581"
          :id="'B3Indices-0'"
        >
          Lixo Acumulado
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ formatarPorcentagem(resultados.bk_303_011_pct_lixo_acumulado) }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #5f78e0"
          :id="'B3Indices-1'"
        >
          Esgoto a céu aberto
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ formatarPorcentagem(resultados.bk_303_011_pct_esgoto_ceu_aberto) }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #1da0f2"
          :id="'B3Indices-2'"
        >
          Água encanada
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{
            formatarPorcentagem(
              resultados.bk_303_009_pct_abastecimento_agua_canalizacao_total
            )
          }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #18817e"
          :id="'B3Indices-3'"
        >
          Pavimentação
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ formatarPorcentagem(resultados.bk_303_011_pct_pavimentacao) }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #4bccc9"
          :id="'B3Indices-4'"
        >
          Alvenaria Exposta
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{
            formatarPorcentagem(
              resultados.bk_303_009_pct_material_predom_paredes_alvenaria_sem_rev
            )
          }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #72e400"
          :id="'B3Indices-5'"
        >
          Arborização
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ formatarPorcentagem(resultados.bk_303_011_pct_arborizacao) }}
        </span>
      </div>
    </div>
    <br />
    <div class="rowSec">
      <div class="itens auto">
        <span class="titleBlue auto">
          <img class="titleIcon" :src="iconAuto" />
          <p class="titleText">Índices relativos a automóveis</p>
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #5065b4"
          :id="'B3Indices-6'"
        >
          Sinistro Furto: Prob.
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ formatarPorcentagem(resultados.bk_308_002_prob_sin1_24_m) }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #5065b4"
          :id="'B3Indices-7'"
        >
          Sinistro Furto: Qtd.
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ resultados.bk_308_001_qtde_sin1_24_m }}
        </span>
      </div>
      <div class="itens">
        <span
          class="itensTitle"
          style="background-color: #5065b4"
          :id="'B3Indices-8'"
        >
          Sinistro Furto: Prêmio
        </span>
        <span class="itensValue" style="border: solid 1px #0070c0">
          {{ formatarParaReais(resultados.bk_308_003_premio_medio_sin1_24_m) }}
        </span>
      </div>
    </div>
    <br />
    <div class="rowSec">
      <div class="columns">
        <div class="left">
          <span class="title-georendas">
            <span class="dest"> GeoRendas: </span>
            Potencial Financeiro da Localidade
          </span>
          <div class="columnsBody">
            <img class="imgGeoRenda" :src="imgGeoRenda" />
            <p class="textGeo -chf" :id="'B3Indices-9'">
              {{ formatarParaReais(resultados.bk_303_005_chefe_reais) }}
            </p>
            <p class="textGeo -med" :id="'B3Indices-10'">
              {{ formatarParaReais(resultados.bk_303_005_media_reais) }}
            </p>
            <p class="textGeo -pcap" :id="'B3Indices-11'">
              {{ formatarParaReais(resultados.bk_303_005_per_capita_reais) }}
            </p>
            <p class="geo-legenda">
              <span class="leg">
                <span class="box-leg -chf"></span>
                Per capita da Família <br />
              </span>
              <span class="leg">
                <span class="box-leg -med"></span>
                Média da Família <br />
              </span>
              <span class="leg">
                <span class="box-leg -pcap"></span>
                Chefe da Família <br />
              </span>
            </p>
          </div>
        </div>
        <div class="right">
          <ScoreSuperBox
            :id="'B3Indices-12'"
            :score="calculaScore"
            :title="'Score de localidade'"
          />
          <div class="gaugeTable">
            <div class="colTab">
              <span class="titleBlue" :id="'B3Indices-13'">
                Rating de Localidade
              </span>
              <span class="tabTextBox">
                <div class="col1">
                  <p
                    v-for="(opcao, i) in [
                      'Frágil',
                      'Suscetível',
                      'Regular',
                      'Bom',
                      'Ótimo',
                      'Excelente',
                    ]"
                    :key="i"
                    :id="'B3Indices-13-' + opcao"
                    class="tabText"
                    :class="{
                      selecionado: resultados.rating_localidade === opcao,
                      'nao-selecionado': resultados.rating_localidade !== opcao,
                    }"
                  >
                    {{ opcao }}
                  </p>
                </div>
                <span class="col2" v-if="abreBloco">
                  <img
                    class="icon-box"
                    :id="'B3Indices-13-NA'"
                    :src="
                      resultados.rating_localidade == '-' ? iconNaAtivo : iconNa
                    "
                  />
                </span>
              </span>
            </div>
            <div class="colTab">
              <span class="titleBlue" :id="'B3Indices-14'">
                Especificidade
              </span>
              <span class="tabTextBoxRow">
                <span class="col1">
                  <p
                    v-for="(opcao, index) in opcoesEspecificidade"
                    :key="opcao + index"
                    class="tabText"
                    :id="'B3Indices-14-' + opcao"
                    :class="{
                      selecionado:
                        resultados.bk_301_004_especificidade === opcao,
                      'nao-selecionado':
                        resultados.bk_301_004_especificidade !== opcao,
                    }"
                  >
                    {{ opcao }}
                  </p>
                </span>
                <span class="col2" v-if="abreBloco">
                  <img
                    class="icon-box"
                    :id="'B3Indices-14-NA'"
                    :src="
                      resultados.bk_301_004_especificidade == '-'
                        ? iconNaAtivo
                        : iconNa
                    "
                  />
                </span>
              </span>
            </div>
            <div class="colTab">
              <span class="titleInterpr" :id="'B3Indices-16'">
                Interpretação
              </span>
              <span class="tabTextBoxInterpr">
                <p class="tabText interpr">
                  <span
                    class="interpr-valor"
                    v-html="interpretacaoFormatada"
                  ></span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsBloco3" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
// import Gauge from "@/components/Visuais/Gauge";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import ScoreSuperBox from "@/components/Visuais/ScoreSuperBox.vue";

export default {
  name: "B3Indices",
  components: {
    // Gauge,
    ScoreSuperBox,
  },
  props: {
    index: Number,
    abreBloco: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconNa: "/img/superbox/NA-gray.png",
      iconNaAtivo: "/img/superbox/NA-blue.png",
      iconAuto: "/img/superbox/icon-auto-white.png",
      imgGeoRenda: "/img/superbox/GeoRendas2.png",

      opcoesEspecificidade: [
        "Centro urbano",
        "Área urbana",
        "Área periférica",
        "Área de borda",
        "Área interiorana-rural",
        "Centro litorâneo",
        "Área litorânea",
      ],
    };
  },

  methods: {
    formatarParaReais(numero) {
      if (numero > 0 && numero != "-") {
        let numeroArredondado = Math.round(numero * 100) / 100;

        let [parteInteira, parteDecimal] = numeroArredondado
          .toFixed(2)
          .split(".");

        parteInteira = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return `R$ ${parteInteira},${parteDecimal.padEnd(2, "0")}`;
      } else {
        return "-";
      }
    },

    formatarPorcentagem(numero) {
      if (numero != "-") {
        if (numero < 100 && numero > 0) {
          return parseFloat(numero).toFixed(2) + "%";
        } else {
          return numero + "%";
        }
      } else {
        return numero;
      }
    },
  },

  computed: {
    tooltipsBloco3() {
      return listaTooltips.bloco03_indices;
    },

    calculaScore() {
      var score = this.resultados.boxcep001_score;
      if (score != "-") {
        return score;
      } else {
        return -9;
      }
    },

    interpretacaoFormatada() {
      var interpretacao = this.resultados.interpretacao_geolocalidade;

      if (interpretacao && interpretacao != "-") {
        interpretacao = interpretacao.replace(
          /'([^']+)'/g,
          function (match, p1) {
            return `<span style="color: #3bc0bf; 
                              font-weight: 500; 
                              width: fit-content;">
                  ${p1}
                  </span>`;
          }
        );
      }
      return interpretacao;
    },
  },
};
</script>

<style lang="scss" scoped>
.bodySec {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 950px;
  cursor: default;
  margin-bottom: 20px;
}

.rowSec {
  display: flex;
  flex: 1;
  gap: 5px;
  width: 950px;
  align-items: flex-end;
  justify-content: center;
}

.titleInterpr {
  background-color: #a69be0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px 5px 0 0;
  cursor: default;
  line-height: 16px;
}

.tabTextBoxInterpr {
  background-color: white;
  border-radius: 0 0 10px 10px;
  padding: 5px 10px;
  margin: 0px 0 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: default;
  text-align: center;
  line-height: 16px;
}

.titleBlue {
  background-color: #0070c0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  cursor: default;
  line-height: 14px;

  &.tit {
    height: 44px;
  }

  &.auto {
    height: 100%;
    background-color: #5065b4;
    flex-direction: column;
  }
  .titleText {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 14px;
  }

  .titleIcon {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
}
.title-georendas {
  color: #0070c0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px 0 30px 0;

  .dest {
    font-size: 24px;
    font-weight: 700;
  }
}
.itens {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 155.828px;
  height: 90px;
  cursor: default;

  &.auto {
    height: 85px;
  }
  &.renda {
    max-width: 90%;
    width: 100%;
  }

  .itensTitle {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;

    &.renda {
      background-color: #0070c0;
      height: 40px;
      justify-content: flex-start;
      padding: 0 0 0 20px;
    }
  }

  .itensValue {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;

    &.renda {
      border: solid 1px #0070c0;
      color: black;
      height: 40px;
    }
  }
}
.columns {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  margin-top: 5px;

  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0 0 0;
    gap: 20px;
    margin: 0px 0 20px 0;
  }
  .columnsBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
  }

  .imgGeoRenda {
    height: 330px;
    margin: 20px;
  }

  .textGeo {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    width: fit-content;
    cursor: default;
    color: #0070c0;
    height: 0;

    &.-chf {
      left: 104px;
      bottom: 342px;
    }
    &.-med {
      left: 104px;
      bottom: 280px;
    }
    &.-pcap {
      left: 104px;
      bottom: 216px;
    }
  }
}
.geo-legenda {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  width: fit-content;
  cursor: default;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 0;
  bottom: 100px;
  left: 70px;
}
.leg {
  display: flex;
  align-items: center;
}
.box-leg {
  width: 15px;
  height: 15px;
  margin: 0 10px 0 0;

  &.-chf {
    background-color: #80ff00;
  }
  &.-med {
    background-color: #41bcc9;
  }
  &.-pcap {
    background-color: #168fa2;
  }
}

.gaugeBox {
  margin: -20px 0 -30px 0;
  transform: scale(0.7);
  cursor: default;
}

.gaugeTable {
  display: flex;
  flex: 1;
  gap: 5px;
  margin-top: 10px;

  .colTab {
    display: flex;
    flex-direction: column;
    width: 155px;
    cursor: default;
  }

  .tabTextBox {
    border: solid 1px #0070c0;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px 0 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    cursor: default;
  }
  .tabTextBoxRow {
    border: solid 1px #0070c0;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
  }
  .col1 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .col2 {
    height: 100%;
    width: 1%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .icon-box {
    height: 35px;
    width: 33px;
    position: absolute;
  }

  .tabText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .tabTextLine {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
  }
  .opcoes {
    margin: 0 5px 0 0 !important;
  }
  .selecionado {
    color: #0070c0;
    margin-inline: 3px;
    font-weight: 600;
  }
  .nao-selecionado {
    color: #a6a6a6;
    margin-inline: 3px;
  }
}
</style>
